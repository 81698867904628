/* eslint-disable max-len */
export const COLORS = {
    BLACK: '#000',
    WHITE: '#FFF',
    RED: '#FF0000',
    GRAY: '#808080',
    BLUE_CHAMPION: '#00345b',
    COLOR_HEADER_NAV_LINK: '#000',
    COLOR_HEADER_NAV_LINK_HOVER: '#7e7e7e',
    BG_COLOR_HEADER: '#FFF',
    BORDER_COLOR_ONLINE_CARS_MENU: '#ccc',
    BODER_BOTTOM_COLOR_NAV_LINK: '#d1d1d1',
    BG_COLOR_ONLINE_CARS: '#fff',
    HOVER_COLOR_NAV_CAR_ONLINE: '#f2f1f1',
    FIG_CAPTION_COLOR_NAV_CAR_ONLINE: '#000',
    INFO_LINK_COLOR_NAV_CAR_ONLINE: '#32302e',
    BORDER_RIGHT_NAV: 'rgb(204, 204, 204)',
    BORDER_BOTTOM_COLOR_MOBILE: 'rgb(204, 204, 204)',
    BG_COLOR_HAM_BUTTON: '#fff',
    BG_COLOR_FOOTER_TOP: '#e9e9e9',
    BG_COLOR_FOOTER_BOTTOM: '#000',
    AUDI_CONFIGURATOR_FOOTER_BG: '#333333',
    TITLE_COLOR_TOP_FOOTER_NAV: '#000',
    // COLOR_FOOTER_BOTTOM_TEXT: '#acaaaa',
    BORDER_CREDITS_SECTION: '#ccc',
    BORDER_SOCIAL_LINKS_SECTION: '#ccc',
    BORDER_TABLE_DATA_COLOR: '#000',
    BG_COLOR_DISABLED_BUTTON: '#cacaca',
    BG_COLOR_MODAL_BACKDROP: 'rgba(0,0,0,0.75)',
    BG_COLOR_MODAL: '#fff',
    // CONFIGURATOR
    BG_COLOR_CONFIGURATOR_STEPS: '#000',
    COLOR_CONFIGURATOR_STEP: '#9b9b9b',
    COLOR_CONFIGURATOR_STEP_ACTIVE: '#fff',
    COLOR_ITEM_CAR_FIGCAPTION: '#242221',
    COLOR_TEXT_ITEM_CAR_SELECT_BUTTON: '#fff',
    COLOR_TEXT_ITEM_CAR_LICENSE_FEE_PRICE: '#717171',
    COLOR_TEXT_MODEL_GROUP_ITEM_PRICE: '#717171',
    BORDER_COLOR_ITEM_CAR_RANK: '#d8d8d8',
    COLOR_TEXT_ITEM_CAR_RANK_POLLUTION: '#000',
    COLOR_TEXT_ITEM_CAR_RANK_SAFETY: '#fff',
    BG_COLOR_ITEM_CAR_RANK_POLLUTION: '#d35330',
    BG_COLOR_ITEM_CAR_RANK_SAFETY: '#4182ba',
    BG_COLOR_CONFIGURATOR_FOOTER: '#fafafa',
    BG_COLOR_IMAGES_SLIDE_ITEM: '#F6F6F6',
    COLOR_TEXT_CONFIG_STEPS_CAR_DESCRIPTION: '#fff',
    INTERNAL_COLOR_ITEM_BORDER_BOTTOM: '#cecece',
    INTERNAL_COLOR_ITEM_PICKER_BORDER: '#fff',
    SPECIFICATION_ITEM_COLOR_BORDER_BOTTOM: '#cdcdcd',
    COLOR_ERROR_CONTACT_US_FIELD: '#e02b27',
    COLOR_ERROR_LABEL_INPUT_FIELD: '#e02b27',
    BG_SPECIFICATIONS_CONTAINER_COLOR: '#fafaf9',
    COLOR_MODEL_ITEM_TITLE: '#242221',
    COLOR_MODEL_ITEM_PRICE: '#242221',
    COLOR_MODEL_COLOR_BAR_TEXT: '#242221',
    BG_COLOR_MODEL_ITEM_SELECTED: '#EDEDED',
    LOADER_BACKGROUND_COLOR: '#fff',

    // ONLINECARS
    BG_COLOR_FOOTER: '#fafafa',
    // MainCharacteristics
    COLOR_CHARACTERISTIC_ITEM_BEFORE: '#aeaba0',
    // Tooltip
    BG_COLOR_TOOLTIP_CONTENT: '#33302e',
    BORDER_COLOR_TOOLTIP: '#fff',
    COLOR_TEXT_TOOLTIP_CONTENT: '#fff',
    // ConfigurationalLeft
    BORDER_COLOR_CONFIGURATION_LEFT: '#cecece',
    BORDER_COLOR_CONFIGURATION_LEFT_HEADER: '#cecece',
    BORDER_COLOR_CONFIGURATION_LEFT_CONTINUE_BUTTON: '#FFF',
    BG_COLOR_CONFIGURATION_LEFT_FOOTER_MOBILE: '#FFF',
    BG_COLOR_CONFIGURATION_LEFT_HEADER: '#FFF',
    COLOR_TEXT_PRICE_DESCRIPTION: '#969696',
    BG_COLOR_INNER_PAGE_MOBILE: 'rgb(238, 238, 238)',
    BG_COLOR_PREVIOUS_PAGE_BUTTON_404: '#4ba82e',
    TEXT_COLOR_PREVIOUS_PAGE_BUTTON_404: '#4ba82e',

    // AddOns
    GROUP_ITEM_COLOR_BORDER_BOTTOM: '#cecece',
    ADD_ON_ITEM_COLOR_BORDER: '#fff',
    AUDI_ADDON_SEPERATOR: 'rgba(214, 214, 214, 0.64)',
    AUDI_ADDON_GROUP_SEPERATOR: 'rgba(31, 28, 28, 0.43)',

    // Summary Share Buttons
    WHATSAPP_ICON_GREEN: '#1bb723',
    FACEBOOK_ICON_BLUE: '#1877f2',
    EMAIL_ICON_GREY: '#7c7c7c',

    // Safety Level Equipment
    BG_COLORS_SAFETY_LEVEL_EQUIPMENT: {
        0: '#aa1f24',
        1: '#d5502d',
        2: '#efba1d',
        3: '#3da47c',
        4: '#417b91',
        5: '#4187ba',
        6: '#2f679f',
        7: '#4b649d',
        8: '#495591',
    },

    BG_COLOR_BORDER_SAFETY_LEVEL_EQUIPMENT: '#fff',
    BG_COLOR_TEXT_SAFETY_LEVEL_EQUIPMENT: '#fff',
    COLOR_TEXT_SAFETY_LEVEL_EQUIPMENT_CELL: '#fff',

    // Poluution Level
    BG_COLORS_POLLUTION_LEVEL: {
        1: '#013319',
        2: '#02502f',
        3: '#13633c',
        4: '#1f754a',
        5: '#4aa547',
        6: '#9cb83d',
        7: '#d4ca26',
        8: '#fbeb02',
        9: '#fecc05',
        10: '#ea8122',
        11: '#e75a25',
        12: '#e94b37',
        13: '#be151c',
        14: '#980b0f',
        15: '#6c0000',
    },

    COLOR_TEXT_POLLUTION_ITEM: '#000',

    SYSTEM_DETAILS_LEGEND: {
        NOT_INSTALLED: '#aa1f24',
        INSTALLED: '#4187ba',
        OPTIONAL_INSTALLATION: '#4187ba',
        DEFAULT: '#4187ba',
    },

    // configurationLeftScrollBar

    BG_COLOR_CONF_SCROLLBAR_THUMB: '#dbdbdb',
    BORDER_COLOR_CONF_SCROLLBAR: '#fff',

    // Contact Us
    BG_COLOR_CONTACT_US_PAGE: '#cccccc',

    // Checkout

    BORDER_COLOR_CHECKOUT_SIDER_BAR: '#cecece',
    BG_COLOR_CHECKOUT_SIDER_BAR: '#fbfbfb',
    BG_COLOR_CHECKOUT_SIDER_BAR_MOBILE: '#f6f6f6',
    BG_COLOR_CHECKOUT_PROGRESS_BAR: '#f1f1f1',
    BG_COLOR_PROGRESS_STEP: '#32302e',
    COLOR_ERROR_LABEL_INPUT_FIELD_CHECKOUT: '#bb2416',
    COLOR_ERROR_RED_GENERAL_1: '#bb2416',
    COLOR_BUTTON_RESEND_SMS: '#125ebb',
    IMAGE_GRAY_BACKGROUND: '#F6F6F6',
};

export const LANGUAGE = {
    HEBREW: 'HEBREW',
};

export const MENUFACTURS = {
    SEAT: 'S',
    SKODA: 'C',
    VOLKSWAGEN: 'V',
    VOLKSWAGEN_COMMERCIAL: 'N',
    AUDI: 'A',
    CUPRA: 'R',
    CHAMPION: 'CHAMPION',
};

export const MUTAG_PATH_NAME = {
    SEAT: 'Seat',
    SKODA: 'Skoda',
    VOLKSWAGEN: 'Volkswagen',
    VOLKSWAGEN_COMMERCIAL: 'Commercial',
    AUDI: 'Audi',
    CUPRA: 'Cupra',
    CHAMPION: 'Champion',
};

export const MUTAG_FOLDER = {
    AUDI: 'AUDI',
    SEAT: 'SEAT',
    SKODA: 'SKODA',
    CUPRA: 'CUPRA',
    VOLKSWAGEN: 'VW',
    VOLKSWAGEN_COMMERCIAL: 'VW-COM',
};

export const MUTAGS = {
    SEAT: 'SEAT',
    SKODA: 'SKODA',
    VOLKSWAGEN: 'VOLKSWAGEN',
    VOLKSWAGEN_COMMERCIAL: 'COMMERCIAL',
    AUDI: 'AUDI',
    CUPRA: 'CUPRA',
    CHAMPION: 'CHAMPION',
};

export const LABEL_MAX_LENGTH = 75;

export const MENUFACTURS_FULL_NAMES = {
    [MENUFACTURS.SEAT]: {
        ENGLISH: 'Seat',
        HEBREW: 'סיאט',
    },
    [MENUFACTURS.SKODA]: {
        ENGLISH: 'Skoda',
        HEBREW: 'סקודה',
    },
    [MENUFACTURS.VOLKSWAGEN]: {
        ENGLISH: 'Volkswagen',
        HEBREW: 'פולקסווגן',
    },
    [MENUFACTURS.VOLKSWAGEN_COMMERCIAL]: {
        ENGLISH: 'Volkswagen',
        HEBREW: 'פולקסווגן',
    },
    [MENUFACTURS.AUDI]: {
        ENGLISH: 'Audi',
        HEBREW: 'Audi',
    },
    [MENUFACTURS.CUPRA]: {
        ENGLISH: 'Cupra',
        HEBREW: 'קופרה',
    },
    [MENUFACTURS.CHAMPION]: {
        ENGLISH: 'Champion',
        HEBREW: "צ'מפיון",
    },
};

export const MENUFACTURS_DISCLAIMER_FULL_NAMES = {
    [MENUFACTURS.SEAT]: {
        ENGLISH: 'Seat',
        HEBREW: 'סיאט',
    },
    [MENUFACTURS.SKODA]: {
        ENGLISH: 'Skoda',
        HEBREW: 'סקודה',
    },
    [MENUFACTURS.VOLKSWAGEN]: {
        ENGLISH: 'Volkswagen',
        HEBREW: 'פולקסווגן',
    },
    [MENUFACTURS.VOLKSWAGEN_COMMERCIAL]: {
        ENGLISH: 'פולקסווגן מסחריות',
        HEBREW: 'פולקסווגן מסחריות',
    },
    [MENUFACTURS.AUDI]: {
        ENGLISH: 'Audi',
        HEBREW: 'Audi',
    },
    [MENUFACTURS.CUPRA]: {
        ENGLISH: 'Cupra',
        HEBREW: 'קופרה',
    },
    [MENUFACTURS.CHAMPION]: {
        ENGLISH: 'Champion',
        HEBREW: "צ'מפיון",
    },
};

export const SIZES = {
    LARGE: 'large',
    SMALL: 'small',
};

export const CAR_TYPE = {
    GASOLINE: 'Gasoline',
    ELECTRIC: 'Electric',
    HYBRID: 'Hybrid',
};

export const LOGOS_PATHS = {
    [MENUFACTURS.SEAT]: {
        [SIZES.LARGE]: require('../assets/logos/s_logo.png'),
        [SIZES.SMALL]: require('../assets/logos/small_logos/s_logo.png'),
        maintenance: require('../assets/logos/maintenance/seat.png'),
    },
    [MENUFACTURS.SKODA]: {
        [SIZES.LARGE]: require('../assets/logos/c_logo.png'),
        [SIZES.SMALL]: require('../assets/logos/small_logos/c_logo.png'),
        maintenance: require('../assets/logos/maintenance/skoda.png'),
    },
    [MENUFACTURS.VOLKSWAGEN]: {
        [SIZES.LARGE]: require('../assets/logos/v_logo.png'),
        [SIZES.SMALL]: require('../assets/logos/small_logos/v_logo.png'),
        maintenance: require('../assets/logos/maintenance/vw.png'),
    },
    [MENUFACTURS.VOLKSWAGEN_COMMERCIAL]: {
        [SIZES.LARGE]: require('../assets/logos/n_logo.png'),
        [SIZES.SMALL]: require('../assets/logos/small_logos/n_logo.png'),
        maintenance: require('../assets/logos/maintenance/vwc.png'),
    },
    [MENUFACTURS.CUPRA]: {
        [SIZES.LARGE]: require('../assets/logos/cupra.png'),
        [SIZES.SMALL]: require('../assets/logos/small_logos/cupra.png'),
        maintenance: require('../assets/logos/maintenance/cupra.png'),
    },
    [MENUFACTURS.AUDI]: {
        [SIZES.LARGE]: require('../assets/logos/a_logo.png'),
        [SIZES.SMALL]: require('../assets/logos/a_logo.png'),
        maintenance: require('../assets/logos/maintenance/audi.png'),
    },
    [MENUFACTURS.CHAMPION]: {
        [SIZES.LARGE]: require('../assets/logos/champios.png'),
        [SIZES.SMALL]: require('../assets/logos/champios.png'),
    },
};

export const MENUFACTURS_WEBSITES = {
    [MENUFACTURS.SEAT]: 'https://www.seat.co.il',
    [MENUFACTURS.SKODA]: 'https://www.skoda.co.il',
    [MENUFACTURS.VOLKSWAGEN]: 'https://www.vw.co.il',
    [MENUFACTURS.VOLKSWAGEN_COMMERCIAL]: 'https://vwcv.co.il',
    [MENUFACTURS.CUPRA]: 'https://cupraofficial.co.il',
    [MENUFACTURS.AUDI]: 'https://www.audi.co.il',
    [MENUFACTURS.CHAMPION]: 'https://www.championmotors.co.il',
};

export const MENUFACTURS_CONTACT_INFORMATION = {
    [MENUFACTURS.SEAT]: {
        EMAIL: 'info@seat.co.il',
        PHONE: '3313*',
    },
    [MENUFACTURS.SKODA]: {
        EMAIL: 'info_skoda@champ.co.il',
        PHONE: '9822*',
    },
    [MENUFACTURS.VOLKSWAGEN]: {
        EMAIL: 'info_vw@champ.co.il',
        PHONE: '6511*',
    },
    [MENUFACTURS.VOLKSWAGEN_COMMERCIAL]: {
        EMAIL: 'info_vwcv@champ.co.il',
        PHONE: '5405*',
    },
    [MENUFACTURS.CUPRA]: {
        EMAIL: 'info_cupra@champ.co.il',
        PHONE: '6331*',
    },
    [MENUFACTURS.AUDI]: {
        EMAIL: 'info_audi@champ.co.il',
        PHONE: '2834*',
    },
    [MENUFACTURS.CHAMPION]: {
        EMAIL: 'info@info.co.il',
        PHONE: '3313*',
    },
};

export const ROUTES = {
    HOME: '/',
    FAQ: '/faq',
    CONTACT: '/contact',
    MODELS: '/models',
    PAYMENT: '/payment',
    ORDER_AND_CANCEL_TERMS: '/order-and-cancel-terms',
    TERMS_OF_WARRANTY: '/',
    TAKANON: '/takanon',
    PRIVACY_POLICY: '/privacy-policy',
    SPECIFICATIONS: '/specifications/:modelCode',
    CAR_INFO: '/car-info',
    CHECKOUT: '/checkout',
    CONFIGURATOR: '/configurator',
    OLD_CONFIGURATOR_WITH_EQUIPMENT_PACKAGES: '/configurator/equipment-packages',
    CONFIGURATOR_WITH_MODELS: '/configurator/models/:modelGroup',
    OLD_CONFIGURATOR_WITH_MODELS: '/configurator/models/:modelGroup/:modelGroupCode',
};

export const IFRAME_MESSAGES = {
    SUCCESS: 'SUCCESS_TRANSACTION',
    FAIL: 'FAILED_TRANSACTION',
    CANCEL: 'CANCELLED_TRANSACTION',
};

// CREDIT-GUARD PARAMERTS NAMES IN URLS
export const CG_PARMS = {
    UNIQUE_ID: 'uniqueID',
    TX_ID: 'txId',
};

export const SCROLL_OFFSET = 300;
export const AUDI_SCROLL_OFFSET = 150;
export const MODEL_SCROLL_OFFSET = 78;

export const SALES_PERSON_CONTACT = 'null45678';

export const ICON_AND_TITLE_OFFSET = -100;
export const TYPES = {
    MUTAG: 'MUTAG',
    MODEL_GROUP: 'MODGR',
    MODEL: 'MODEL',
    COLOR_OUT: 'COLOR_OUT',
    COLOR_INT: 'COLOR_INT',
    LMEMI: 'LMEMI',
    SAH: 'SAH',
    AGRA: 'AGRA',
    MAAM: 'MAAM',
    MEHIRON: 'MEHIRON',
    DATE_AGAA: 'DATE_AGAA',
    MEMIS: 'MEMIS',
    NAME_SNIF: 'NAME_SNIF',
    TELF_SNIF: 'TELF_SNIF',
    MAIL_SNIF: 'MAIL_SNIF',
    NAME_USER: 'NAME_USER',
    TELF_USER: 'TELF_USER',
    MAIL_USER: 'MAIL_USER',
    JANT: 'MEMIS_JANT',
    GIMUR: 'GIMUR',
    EQUIPMENT_PACKAGE: 'EQUIPMENT_PACKAGE',
    ORIGINAL_ADDITION: 'ORIGINAL_ADDITION',
};

export const PAYMENT_STEPS = {
    INITIAL: 'initial',
    PAYMENT: 'payment',
    FAILURE: 'failure',
    SUCCESS: 'success',
};

export const FAIL_CODES = {
    UNKNOWN: 'ERR2210', // שגיאה כללית - לא מוגדרת
    INVALLID_OBJECT_DATA: 'ERR2204', // אובייקט הצעת מחיר לא תקין
    LOADING_DATA_FAILED: 'ERR2205', // שגיאה בטעינת פרטי הצעת מחיר
    LOADING_PAYMENT_URL_FAILED: 'ERR2206', // שגיאה בטעינת עמוד תשלום
};

export const CONFIGURATOR_STEPS = {
    MODEL: 1,
    ENGINE_AND_TRIM_LEVEL: 2,
    EQUIPMENT_PACKAGES: 3,
    COLOR: 4,
    INTERNAL_COLOR: 5,
    HOOPS: 6,
    ADD_ONS: 7,
    SUMMARY: 8,
};

export const DEFAULT = 'DEFAULT';
export const DEFAULT_WHEEL = 'Default Wheel';

export const FILTER_BY = Object.freeze({
    BY_TYPE: 0,
    BY_MODEL: 1,
});

export const TITLE_FIELD = Object.freeze({
    MODEL_TYPE: 'modelType',
    MANUFACTURER: 'manufacturer',
});

export const REGISTER_REASON = Object.freeze({
    DATA_CONFLICT: 0,
    NEW_CUSTOMER: 1,
});

export const ICONS_NAMES = {
    PLUS: 'PLUS',
    CLOSE: 'CLOSE',
    ARROW_LEFT_AUDI: 'ARROW_LEFT_AUDI',
    ARROW_LEFT: 'ARROW_LEFT',
    ARROW_RIGHT: 'ARROW_RIGHT',
    THIN_ARROW_RIGHT: 'THIN_ARROW_RIGHT',
    CHECK_MARK: 'CHECK_MARK',
    C360: 'C360',
    GALLERY: 'GALLERY',
    C360_DARK: 'C360_DARK',
    GALLERY_DARK: 'GALLERY_DARK',
    CHECK_MARK_WHITE: 'CHECK_MARK_WHITE',
    HAMBURGER: 'HAMBURGER',
    MENU_ARROW: 'MENU_ARROW',
    QUESTION_MARK: 'QUESTION_MARK',
    ADD: 'ADD',
    REMOVE: 'REMOVE',
    CLOSE_BLACK: 'CLOSE_BLACK',
    BEFORE_ARROW_BLACK: 'BEFORE_ARROW_BLACK',
    BEFORE_ARROW_WHITE: 'BEFORE_ARROW_WHITE',
    SELECT_ARROW: 'SELECT_ARROW',
    DOWNLAOD_SPECIFICATIONS: 'DOWNLAOD_SPECIFICATIONS',
    SOCIAL_FACEBOOK: 'SOCIAL_FACEBOOK',
    SOCIAL_INSTAGRAM: 'SOCIAL_INSTAGRAM',
    SOCIAL_YOUTUBE: 'SOCIAL_YOUTUBE',
    SOCIAL_TIKTOK: 'SOCIAL_TIKTOK',
    SOCIAL_FACEBOOK_DARK: 'SOCIAL_FACEBOOK_DARK',
    SOCIAL_INSTAGRAM_DARK: 'SOCIAL_INSTAGRAM_DARK',
    SOCIAL_YOUTUBE_DARK: 'SOCIAL_YOUTUBE_DARK',
    SOCIAL_TIKTOK_DARK: 'SOCIAL_TIKTOK_DARK',
    CLOSE_WHITE: 'CLOSE_WHITE',
    IDENTIFY_ICON: 'IDENTIFY_ICON',
    IDENTIFY_ICON_AUDI: 'IDENTIFY_ICON_AUDI',
    MENU_ARROW_DOWN: 'MENU_ARROW_DOWN',
    MENU_ARROW_DOWN_BLACK: 'MENU_ARROW_DOWN_BLACK',
    PERSON: 'PERSON',
    LOCATION: 'LOCATION',
    LOCATION_AUDI: 'LOCATION_AUDI',
    CALCULATE: 'CALCULATE',
    PAYMENT: 'PAYMENT',
    HAPPY_FACE: 'HAPPY_FACE',
    CHAT: 'CHAT',
    NEW_WINDOW_LINK: 'NEW_WINDOW_LINK',
    CREDIT_CARD_PCI: 'CREDIT_CARD_PCI',
    CREDIT_CARD_RAPIDSSL_SSL_CERTIFICATE: 'CREDIT_CARD_RAPIDSSL_SSL_CERTIFICATE',
    DOWN_CHEVRON: 'DOWN_CHEVRON',
    FACEBOOK_ICON: 'FACEBOOK_ICON',
    WHATSAPP_ICON: 'WHATSAPP_ICON',
    EMAIL_ICON: 'EMAIL_ICON',
    CHEERS_ICON: 'CHEERS_ICON',
};

export const HEADER_NAV_ITEMS_IDS = {
    ONLINE_MODELS: 'online_models',
    FAQ: 'faq',
    CONTACT: 'contact',
};

export const POLLUTION = 'POLLUTION';
export const SAFETY = 'SAFETY';
export const DEVELOPMENT_ENV = 'development';
export const ERROR_CODES = Object.freeze({
    INTERNAL_SERVER_ERROR: 500,
    NOT_FOUND: 404,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    CONFLICT: 409,
    PURCHASE_LIMIT: 452,
});

export const STATUS_CODES = Object.freeze({
    SUCCESS: 200,
    VERY_SUCCESS: 210,
});

export const API_METHODS = {
    GET_MODEL_HEADER: 'getModelHeader',
    GET_MODEL_DETAILS: 'getModelDetails',
    GET_SPECIFICATIONS: 'getSpecifications',
    GET_ENGINE_SPECIFICATIONS: 'getEngineSpecifications',
    GET_SIZE_SPECIFICATIONS: 'getSizeSpecifications',
    GET_SPECIFICATIONS_INIT: 'getTabsSpecifications',
    GET_INTERNAL_COLORS: 'getInternalColors',
    GET_EQUIPMENT_INTERNAL_COLORS: 'getCarEquipmentInternalColors',
    GET_ORIGINAL_PACKAGES: 'getOriginalPackages',
    GET_MODEL_LOCAL_GROUPS: 'getModelLocalGroups',
    GET_CASH: 'getCash',
    SEND_SMS: 'sendSms',
    GET_AUTHENTICATED: 'getAuthenticated',
    CHECK_OTP: 'checkOTP',
    CHECK_OTP2: 'checkOTP2',
    CheckSMS: 'checkSms',
    INSERT_LEAD_WITH_CID_SOURCE_TYPE_AND_MARKETING_INFO: 'InsertLeadWithCIDSourceTypeAndMarketingInfo',
    FIRST_CUSTOMER_ENTRANCE: 'FirstCustomerEntrace',
    NEW_CUSTOMER_ENTRANCE: 'NewCustomerEntrace',
    GET_DESTINATIONS: 'GetDestinations',
    HOLD_COMMISION: 'HoldCommision',
    CREATE_ORDER: 'CreateOrder',
    RESEND_SMS: 'ReSendSMSCode',
    GET_CAR_EQUIPMENT_PACKAGES: 'getCarEquipmentPackages',
    GET_MUTAG_DETAILS: 'GetMutagDetails',
};

export const NEW_API_METHODS = {
    AUTH: {
        REQUEST_OTP: 'Auth/RequestOtp',
        REGENERATE_OTP: 'Auth/RegenerateOtp',
        VALIDATE_OTP: 'Auth/ValidateOtp',
        REQUEST_OTP_BY_CLIENT: 'Auth/RequestOtpByClientId',
        REGENERATE_OTP_BY_CLIENT: 'Auth/RegenerateOtpByClientId',
        VALIDATE_ID_OTP: 'Auth/ValidateIdOtp',
    },
    VEHICLE: {
        GET_ALL_MODELS: 'Vehicle/GetAllModels',
        GET_MODELS_ADDITIONAL_OPTIONS: 'Vehicle/{groupModelCode}/{modelCode}/GetModelsAdditionalOptions',
        GET_SPECIFICATIONS: 'Vehicle/{modelCode}/GetSpecifications',
        GET_ENGINE_SPECIFICATIONS: 'Vehicle/{modelCode}/GetEngineSpecification',
        GET_SIZE_SPECIFICATIONS: 'Vehicle/{groupModelCode}/GetSizeSpecifications',
        GET_POLLUTION_AND_SAFETY_DETAILS: 'Vehicle/{modelCode}/GetPollutionAndSafetyDetails',
        GET_POLLUTION_AND_SAFETY_DETAILS_V2: 'Vehicle/{modelCode}/GetPollutionAndSafetyDetailsV2', // TODO: will be deprecated!,
        GET_GROUP_POLLUTION_AND_SAFETY_DETAILS: 'Vehicle/{groupModelCode}/GetGroupPollutionAndSafetyDetails',
        GET_GROUP_POLLUTION_AND_SAFETY_DETAILS_V2: 'Vehicle/{groupModelCode}/GetGroupPollutionAndSafetyDetailsV2', // TODO: will be deprecated!,
        GET_MANUFACTURING_DATES: 'Vehicle/GetModelGroupManufacturingDates',
        GET_BRAND_VIDEO_LINK: 'Vehicle/GetBrandVideoLink',
    },
    CONFIGURATOR: {
        GET_MODEL_DETAILS: 'Configurator/{modelCode}/GetModelDetails',
        GET_EXTERNAL_COLORS: 'Configurator/{modelCode}/GetAvailableColors',
        GET_INTERNAL_COLORS: 'Configurator/{modelCode}/GetAvailableUpholsteries',
        GET_RIMS: 'Configurator/{modelCode}/GetAvailableRims',
        GET_EQUIPMENT_PACKAGE: 'Configurator/{modelCode}/GetEquipmentPackage',
        GET_LOCAL_GROUPS: 'Configurator/{modelCode}/GetAvailableLocalPackages',
        GET_FINAL_PRICE: 'Configurator/{modelCode}/GetFinalPrice',
    },
    CREDIT_GUARD: {
        GET_CREDIT_CARD_IFRAME: 'CreditGuard/GetCreditGuardIFrame',
    },
    CUSTOMER: {
        CREATE_CUSTOMER: 'Customer/CreateCustomer',
        IS_ADDITIONAL_INFORMATION_FILLING_REQUIRED: 'Customer/IsAdditionalInformationFillingRequired',
        FILL_ADDITIONAL_REQUIRED_INFORMATION: 'Customer/FillAdditionalRequiredInformation',
    },
    LEAD: {
        CREATE_LEAD: 'Lead/CreateLead',
        CREATE_LEAD_WITH_ORDER_DETAILS: 'Lead/CreateLeadWithOrderDetails',
    },
    ORDER: {
        HOLD_COMMISION: 'Order/HoldCommision',
        CONFIRM_PAYMENT: 'Order/ConfirmPayment',
    },
    ORGANIZATION: {
        GET_DEALERSHIPS: 'Organization/GetDealerships',
    },
};

export const SYSTEM_DETAILS_LEGEND = {
    NOT_INSTALLED: 'NotInstalled',
    INSTALLED: 'Installed',
    OPTIONAL_INSTALLATION: 'Optional',
    VALUE: 'WithCount',
};

export const SPECIFICATION_STATUS = {
    NOT_INSTALLED: 0,
    INSTALLED: 1,
};

export const CONTACT_US_OPTIONS_VALUES = {
    CUSTOMER_CARE: 'CustomerCare',
    TECHNICAL: 'Technical',
    FUNDING_AND_INSURANCE: 'FundingAndInsurance',
    ORDER: 'Order',
};

export const MENUFACTURS_SOCIAL_LINKS = {
    [MENUFACTURS.SEAT]: {
        FACEBOOK: 'https://www.facebook.com/Club.SEAT',
        INSTAGRAM: 'https://www.instagram.com/seat.israel',
        YOUTUBE: 'https://www.youtube.com/user/newseatibiza',
        TIKTOK: '',
    },
    [MENUFACTURS.SKODA]: {
        FACEBOOK: 'https://www.facebook.com/Skoda.IL',
        INSTAGRAM: 'https://www.instagram.com/skoda_israel_official',
        YOUTUBE: 'https://www.youtube.com/channel/UCfosOFWWhNse5H7_fhBc6zQ/videos',
        TIKTOK: 'https://www.tiktok.com/@skodaisrael',
    },
    [MENUFACTURS.VOLKSWAGEN]: {
        FACEBOOK: 'https://www.facebook.com/VolkswagenIsrael',
        INSTAGRAM: 'https://www.instagram.com/volkswagen_il',
        YOUTUBE: 'https://www.youtube.com/user/VolksWagenIsrael',
        TIKTOK: '',
    },
    [MENUFACTURS.VOLKSWAGEN_COMMERCIAL]: {
        FACEBOOK: 'https://www.facebook.com/VolkswagenCommercial',
        INSTAGRAM: 'https://www.instagram.com/volkswagen_commercial_il',
        YOUTUBE: 'https://www.youtube.com/@user-hq4gu6ie6c',
        TIKTOK: '',
    },
    [MENUFACTURS.CUPRA]: {
        FACEBOOK: 'https://www.facebook.com/CUPRA.IL',
        INSTAGRAM: 'https://www.instagram.com/volkswagen_commercial_il/',
        YOUTUBE: 'https://www.youtube.com/channel/UCDfakv_s9aMAMLyx0MXAAgw',
        TIKTOK: '',
    },
    [MENUFACTURS.AUDI]: {
        FACEBOOK: 'https://www.facebook.com/Audi.israel',
        INSTAGRAM: 'https://www.instagram.com/audi_il/?igshid=79lxxnuayymm',
        YOUTUBE: 'https://www.youtube.com/@AudiIL',
        TIKTOK: '',
    },
    [MENUFACTURS.CHAMPION]: {
        FACEBOOK: '',
        INSTAGRAM: '',
        YOUTUBE: '',
        TIKTOK: '',
    },
};

export const ACCESSIBILITY_DECLARATION_LINKS = {
    [MENUFACTURS.SEAT]: 'https://www.seat.co.il/about/accessibility-declaration',
    [MENUFACTURS.SKODA]: 'https://www.skoda.co.il/accessibility',
    [MENUFACTURS.VOLKSWAGEN]: 'https://www.vw.co.il/about/accessibility',
    [MENUFACTURS.VOLKSWAGEN_COMMERCIAL]: 'https://vwcv.co.il/accessibility-statement/',
    [MENUFACTURS.CUPRA]: 'https://cupraofficial.co.il/accessibility',
    [MENUFACTURS.AUDI]: 'https://www.audi.co.il/accessibility',
    [MENUFACTURS.CHAMPION]: '',
};

export const CHAMPION_MEDIA_URL = process.env.REACT_APP_CHAMPION_MEDIA_URL;
export const LINK_TO_PERSONAL_CHAMPION = 'https://personal.championmotors.co.il';

export const MONTHS_IN_HEBREW = [
    'ינואר',
    'פברואר',
    'מרץ',
    'אפריל',
    'מאי',
    'יוני',
    'יולי',
    'אוגוסט',
    'ספטמבר',
    'אוקטובר',
    'נובמבר',
    'דצמבר',
];

export const CHECKOUT_PAGE_PROGRESS_STEPS = {
    IDENTIFICATION_AND_REGISTRATION: 0,
    PICK_UP_AND_FINANCING: 1,
    PAYMENT_AND_DOWN_PAYMENT: 2,
    SUCCESS: 3,
    FAILURE: 4,
};

export const CHECKOUT_PAGE_FORMS = {
    IDENTIFICATION_FORM: 1,
    REGISTRATION_FORM: 2,
    OTP_FORM: 3,
};

export const OTP_NUMBER = {
    OTP_ONE: 1,
    OTP_TWO: 2,
};

export const SESSION_AUTHORIZATION_TOKEN = 'authorizationToken';
export const PHONE_NUMBER_AUTHORIZATION = 'phone';
export const CUSTOMER_NAME_AUTHORIZATION = 'customerName';
export const IS_VERIFIED_AUTHORIZATION = 'isVerified';
export const HEADERS = {
    AUTHORIZATION: 'Authorization',
};

export const MEDIA_MODE = {
    STATIC: 0,
    EXTERNAL: 1,
    INTERNAL: 2,
};

export const MODEL_GROUPS_CODE = {
    [MENUFACTURS.SEAT]: {
        LEON: 21,
        IBIZA: 22,
        ARONA: 74,
        ATECA: 77,
    },
    [MENUFACTURS.SKODA]: {
        KAROQ: 69,
        NEW_OCTAVIA: 80,
        OCTAVIA: 82,
        NEW_OCTAIVE_SPACE: 81,
        SCALA: 86,
        KAMIQ: 89,
        SUPERB: 90,
        KODIAQ: 93,
        ENYAQ: 91,
    },
    [MENUFACTURS.VOLKSWAGEN]: {
        PASSAT: 5,
        TIGUAN: 31,
        TIGUAN_ALL_SPACE: 4,
        T_CROSS: 79,
        POLO: 2,
        GOLF: 3,
        TOUAREG: 42,
    },
    [MENUFACTURS.VOLKSWAGEN_COMMERCIAL]: {
        AMAROK: 58,
    },
    [MENUFACTURS.CUPRA]: {
        FORMENTOR: 27,
        LEON: 28,
        ATECA: 30,
    },
};

export const TRIM_LEVELS_COMPARISON_LINK = {
    [MENUFACTURS.SEAT]: 'https://www.seat.co.il/models/models-compare',
    [MENUFACTURS.SKODA]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].KAROQ]: 'https://www.skoda.co.il/models/karoq/#about5',
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].NEW_OCTAVIA]: 'https://www.skoda.co.il/models/new-octavia/#about5',
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].SCALA]: 'https://www.skoda.co.il/models/scala/#about7',
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].KAMIQ]: 'https://www.skoda.co.il/models/kamiq#about5',
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].SUPERB]: 'https://www.skoda.co.il/models/superb/#about5',
    },
    [MENUFACTURS.VOLKSWAGEN]: 'https://www.vw.co.il/models/models-compare',
    [MENUFACTURS.VOLKSWAGEN_COMMERCIAL]: 'https://vwcv.co.il/models/models-compare/',
    [MENUFACTURS.CUPRA]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.CUPRA].FORMENTOR]: 'https://cupraofficial.co.il/cars/cupra-formentor/#modelPrices13',
        [MODEL_GROUPS_CODE[MENUFACTURS.CUPRA].LEON]: 'https://cupraofficial.co.il/cars/new-leon-cupra/#modelPrices12',
        [MODEL_GROUPS_CODE[MENUFACTURS.CUPRA].ATECA]: 'https://cupraofficial.co.il/cars/new-cupra-ateca/#modelPrices11',
    },
};

export const DEVICE_TYPE = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
};

export const MODEL_FURTHER_INFORMATION_LINK = {
    [MENUFACTURS.SEAT]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.SEAT].LEON]: 'https://www.seat.co.il/models/leon',
        [MODEL_GROUPS_CODE[MENUFACTURS.SEAT].IBIZA]: 'https://www.seat.co.il/models/ibiza',
        [MODEL_GROUPS_CODE[MENUFACTURS.SEAT].ARONA]: 'https://www.seat.co.il/models/arona',
        [MODEL_GROUPS_CODE[MENUFACTURS.SEAT].ATECA]: 'https://www.seat.co.il/models/ateca',
    },
    [MENUFACTURS.SKODA]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].KAROQ]: 'https://www.skoda.co.il/models/karoq',
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].NEW_OCTAVIA]: 'https://www.skoda.co.il/models/octavia',
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].SCALA]: 'https://www.skoda.co.il/models/scala',
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].KAMIQ]: 'https://www.skoda.co.il/models/kamiq',
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].SUPERB]: 'https://www.skoda.co.il/models/superb',
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].KODIAQ]: 'https://www.skoda.co.il/models/kodiaq',
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].ENYAQ]: 'https://www.skoda.co.il/models/enyaq',
    },
    [MENUFACTURS.VOLKSWAGEN]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].PASSAT]: 'https://www.vw.co.il/models/passat',
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].TIGUAN_ALL_SPACE]: 'https://www.vw.co.il/models/tiguan-allspace',
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].TIGUAN]: 'https://www.vw.co.il/models/tiguan',
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].T_CROSS]: 'https://www.vw.co.il/models/t-cross',
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].POLO]: 'https://www.vw.co.il/models/polo',
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].GOLF]: 'https://www.vw.co.il/models/golf',
    },
    [MENUFACTURS.CUPRA]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.CUPRA].FORMENTOR]: 'https://cupraofficial.co.il/cars/cupra-formentor',
        [MODEL_GROUPS_CODE[MENUFACTURS.CUPRA].LEON]: 'https://cupraofficial.co.il/cars/new-leon-cupra',
        [MODEL_GROUPS_CODE[MENUFACTURS.CUPRA].ATECA]: 'https://cupraofficial.co.il/cars/new-cupra-ateca',
    },
};

export const COLOR_TYPES = {
    COLOR_INT: 'COLOR_INT',
    COLOR_OUT: 'COLOR_OUT',
};

export const LEAD_CUSTOM_CONNECTION_INFO = {
    [MENUFACTURS.SEAT]: {
        campaignId: '1069',
        sourceId1: '103',
        sourceId2: '149',
    },
    [MENUFACTURS.SKODA]: {
        campaignId: '1067',
        sourceId1: '103',
        sourceId2: '149',
    },
    [MENUFACTURS.VOLKSWAGEN]: {
        campaignId: '1068',
        sourceId1: '103',
        sourceId2: '149',
    },
    [MENUFACTURS.VOLKSWAGEN_COMMERCIAL]: { // TODO:
        campaignId: '1068',
        sourceId1: '103',
        sourceId2: '149',
    },
    [MENUFACTURS.CUPRA]: {
        campaignId: '1315',
        sourceId1: '103',
        sourceId2: '211',
    },
    [MENUFACTURS.AUDI]: {
        campaignId: '1402',
        sourceId1: '103',
        sourceId2: '209',
    },
};

export const ACCESSIBILITY_SCRIPTS = {
    [MENUFACTURS.SEAT]: 'https://acsbapp.com/apps/app/dist/js/app.js',
    [MENUFACTURS.SKODA]: 'https://portal.allyable.com/aweb?license=632a81cb37d942a9a05562c0f6a5f539',
    [MENUFACTURS.VOLKSWAGEN]: 'https://portal.allyable.com/aweb?license=8b00aca64ae6416183c37292f7a5f539',
    [MENUFACTURS.VOLKSWAGEN]: '',
    [MENUFACTURS.CUPRA]: '',
    [MENUFACTURS.AUDI]: '',
    [MENUFACTURS.CHAMPION]: '',
};

/**
 * meta data for each model group
 */
export const MODEL_GROUP_META_DATA =  {
    [MENUFACTURS.SEAT]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.SEAT].IBIZA]: {
            title: 'דגמי סיאט איביזה אונליין | Seat Online',
            description: 'הסיאט איביזה החדשה שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Ibiza במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SEAT].LEON]: {
            title: 'דגמי סיאט לאון אונליין | Seat Online',
            description: ' - הסיאט לאון החדשה שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Leon במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SEAT].ARONA]: {
            title: 'דגמי סיאט ארונה אונליין | Seat Online',
            description: 'הסיאט ארונה החדשה שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Arona במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SEAT].ATECA]: {
            title: 'דגמי סיאט אטקה אונליין | Seat Online',
            description: ' - הסיאט אטקה החדשה שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Ateca במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
    },
    [MENUFACTURS.VOLKSWAGEN]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].POLO]: {
            title: 'דגמי פולקסווגן פולו אונליין | Volkswagen Online',
            description: ' - הפולקסווגן פולו שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Polo במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].TIGUAN]: {
            title: 'דגמי פולקסווגן טיגואן אונליין | Volkswagen Online',
            description: ' - הפולקסווגן טיגואן שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Tiguan במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].TIGUAN_ALL_SPACE]: {
            title: 'דגמי פולקסווגן טיגואן אולספייס אונליין | Volkswagen Online',
            description: 'פולקסווגן טיגואן אולספייס שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Tiguan Allspace במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].PASSAT]: {
            title: 'דגמי פולקסווגן פאסאט אונליין | Volkswagen Online',
            description: ' - הפולקסווגן פאסאט שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Passat במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].T_CROSS]: {
            title: 'דגמי פולקסווגן טי קרוס אונליין | Volkswagen Online',
            description: ' - הפולקסווגן טי קרוס שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של T-Cross במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].TOUAREG]: {
            title: 'דגמי פולקסווגן טוארג אונליין | Volkswagen Online',
            description: ' - הפולקסווגן טוארג שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Touareg במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].GOLF]: {
            title: 'דגמי פולקסווגן גולף אונליין | Volkswagen Online',
            description: ' - הפולקסווגן גולף שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Golf במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
    },
    [MENUFACTURS.VOLKSWAGEN_COMMERCIAL]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN_COMMERCIAL].AMAROK]: {
            title: 'דגמי פולקסווגן אמארוק אונליין | Volkswagen Commercial Online',
            description: ' - הפולקסווגן אמארוק שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Amarok במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
    },
    [MENUFACTURS.SKODA]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].SCALA]: {
            title: 'דגמי סקודה סקאלה אונליין | Skoda Online',
            description: ' - הסקודה סקאלה שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Scala במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].OCTAVIA]: {
            title: 'דגמי סקודה אוקטביה אונליין | Skoda Online',
            description: ' - הסקודה אוקטביה שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Octavia במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].NEW_OCTAVIA]: {
            title: 'דגמי סקודה אוקטביה RS אונליין | Skoda Online',
            description: 'הסקודה אוקטביה RS שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Octavia RS במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].SUPERB]: {
            title: 'דגמי סקודה סופרב אונליין | Skoda Online',
            description: ' - הסקודה סופרב שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Superb במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].KAMIQ]: {
            title: 'דגמי סקודה קאמיק אונליין | Skoda Online',
            description: ' - הסקודה קאמיק שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Kamiq במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].KAROQ]: {
            title: 'דגמי סקודה קארוק אונליין | Skoda Online',
            description: ' - הסקודה קארוק שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Karoq במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].KODIAQ]: {
            title: 'דגמי סקודה קודיאק אונליין | Skoda Online',
            description: ' - הסקודה קודיאק שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Kodiaq במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].ENYAQ]: {
            title: 'דגמי סקודה אניאק אונליין | Skoda Online',
            description: ' - הסקודה אניאק שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Enyaq במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
    },
    [MENUFACTURS.CUPRA]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.CUPRA].FORMENTOR]: {
            title: 'דגמי CUPRA פוקרמנטור אונליין | CUPRA Online',
            description: ' - הקופרה פורמנטור שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Formentor במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.CUPRA].LEON]: {
            title: 'דגמי CUPRA אטקה אונליין | CUPRA Online',
            description: ' - הקופרה אטקה שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Ateca במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.CUPRA].ATECA]: {
            title: 'דגמי CUPRA לאון אונליין | CUPRA Online',
            description: ' - הקופרה לאון שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! מגוון דגמי האונליין של Leon במחירים מפתיעים ומפרט עשיר שלא ישאיר אתכם אדישים',
        },
    },
};

/**
 * meta data for specific model 
 * EACH key is unique and it composed of a modelGroupCode and styleLevelCode
 */

export const MODEL_META_DATA = {
    [MENUFACTURS.SEAT]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.SEAT].IBIZA]: {
            2247: {
                title: 'Seat Ibiza Reference Online | Seat Online',
                description: ' - הסיאט איביזה Reference שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סיאט',
            },
            2257: {
                title: 'Seat Ibiza Style Online | Seat Online',
                description: ' - הסיאט איביזה Style שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סיאט',
            },
            2234: {
                title: 'Seat Ibiza FR Online | Seat Online',
                description: ' - הסיאט איביזה FR שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סי',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SEAT].LEON]: {
            2134: {
                title: 'Seat Leon Style Online | Seat Online',
                description: ' - הסיאט לאון Style שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סיאט',
            },
            2157: {
                title: 'Seat Leon FR Online | Seat Online',
                description: ' - הסיאט לאון FR שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סיאט',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SEAT].ARONA]: {
            7457: {
                title: 'Seat Arona Style Online | Seat Online',
                description: ' - הסיאט ארונה Style שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סיאט',
            },
            7434: {
                title: 'Seat Arona FR Online | Seat Online',
                description: ' - הסיאט ארונה FR שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סיאט',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SEAT].ATECA]: {
            7757: {
                title: 'Seat Ateca Style Online | Seat Online',
                description: ' - הסיאט אטקה Style שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סיאט',
            },
            7786: {
                title: 'Seat Ateca XP Online | Seat Online',
                description: ' - הסיאט אטקה XP שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סיאט',
            },
            7734: {
                title: 'Seat Ateca FR Online | Seat Online',
                description: ' - הסיאט אטקה FR שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סיא',
            },
        },
    },
    [MENUFACTURS.VOLKSWAGEN]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].POLO]: {
            257: {
                title: 'Volkswagen Polo Online | Volkswagen Online',
                description: 'הפולקסווגן פולו Style שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסווגן',
            },
            259: {
                title: 'Volkswagen Polo Plus Online | Volkswagen Online',
                description: ' - הפולקסווגן פולו Style Plus שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסווגן',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].PASSAT]: {
            565: {
                title: 'Volkswagen Passat Business | Volkswagen Online',
                description: ' - הפולקסווגן פאסאט Business מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסווגן',
            },
            573: {
                title: 'Volkswagen Passat Business Plus | Volkswagen Online',
                description: ' - הפולקסווגן פאסאט Business Plus מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסוו',
            },
            574: {
                title: 'olkswagen Passat Elegance | Volkswagen Online',
                description: ' - הפולקסווגן פאסאט Elegance מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסווג',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].T_CROSS]: {
            7987: {
                title: 'Volkswagen T-Cross Life | Volkswagen Online',
                description: ' - הפולקסווגן טי קרוס Life מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסווגן',
            },
            7988: {
                title: 'Volkswagen T-Cross Life Plus | Volkswagen Online',
                description: ' - הפולקסווגן טי קרוס Life Plus מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסווגן',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].GOLF]: {
            387: {
                title: 'Volkswagen Golf Life | Volkswagen Online',
                description: 'הפולקסווגן גולף Life מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסווגן',
            },
            388: {
                title: 'Volkswagen Golf Life Plus',
                description: 'הפולקסווגן גולף Life Plus מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסווגן',
            },
            336: {
                title: 'olkswagen Golf GTI | Volkswagen Online',
                description: 'הפולקסווגן גולף GTI עם 245 כ"ס מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסווגן',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].TOUAREG]: {
            4243: {
                title: 'Volkswagen Touareg | Volkswagen Online',
                description: 'הפולקסווגן טוארג מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסווגן',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].TIGUAN]: {
            3187: {
                title: 'Volkswagen Tiguan Life Online | Volkswagen Online',
                description: 'הפולקסווגן טיגואן Life שלכם מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסווגן',
            },
            3188: {
                title: 'Volkswagen Tiguan Life Online | Volkswagen Online',
                description: 'הפולקסווגן טיגואן Life Plus מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של פולקסווגן',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.VOLKSWAGEN].TIGUAN]: {
            410: {
                title: 'Volkswagen Tiguan Allspace Elegance Premium Online',
                description: 'הפולקסווגן טיגואן אולספייס Elegance Premium מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד.',
            },
        },
    },
    [MENUFACTURS.SKODA]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].SCALA]: {
            867: {
                title: 'Skoda Scala Ambition | Skoda Online',
                description: ' - הסקודה סקאלה Ambition מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            8657: {
                title: 'Skoda Scala Style | Skoda Online',
                description: ' - הסקודה סקאלה Style מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            8653: {
                title: 'Skoda Scala Sport | Skoda Online',
                description: ' - הסקודה סקאלה Sport מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },

        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].OCTAVIA]: {
            827: {
                title: 'Skoda Octavia Ambition',
                description: 'הסקודה אוקטביה Ambition מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            8280: {
                title: 'Skoda Octavia Dynamic',
                description: 'הסקודה אוקטביה Dynamic מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },

            8257: {
                title: 'Skoda Octavia Sport',
                description: 'הסקודה אוקטביה Sport מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },

        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].NEW_OCTAVIA]: {
            807: {
                title: 'New Skoda Octavia Ambition',
                description: 'הסקודה אוקטביה Ambition מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            8080: {
                title: 'New Skoda Octavia Dynamic',
                description: 'הסקודה אוקטביה Dynamic מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },

            8057: {
                title: 'New Skoda Octavia Sport',
                description: 'הסקודה אוקטביה Sport מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            8048: {
                title: 'אוקטביה RS',
                description: 'הסקודה אוקטביה RS מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודהמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].SUPERB]: {
            9065: {
                title: 'Skoda Superb Business | Skoda Online',
                description: 'הסקודה סופרב Business מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            907: {
                title: 'Skoda Superb Ambition | Skoda Online',
                description: 'הסקודה סופרב Ambition מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            9057: {
                title: 'Skoda Superb Style | Skoda Online',
                description: 'הסקודה סופרב Style מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            9056: {
                title: 'Skoda Superb Sportline | Skoda Online',
                description: 'הסקודה סופרב Sportline מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            9040: {
                title: 'Skoda Superb L&K | Skoda Online',
                description: 'הסקודה סופרב L&K מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].KAMIQ]: {
            897: {
                title: 'koda Kamiq Ambition | Skoda Online',
                description: 'הסקודה קאמיק Ambition מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            8957: {
                title: 'Skoda Kamiq Style | Skoda Online',
                description: 'הסקודה קאמיק Style מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].KAROQ]: {
            697: {
                title: 'Skoda Karoq Ambition | Skoda Online',
                description: 'הסקודה קארוק Ambition מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            6957: {
                title: 'Skoda Karoq Style | Skoda Online',
                description: 'הסקודה קארוק Style מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            6946: {
                title: 'Skoda Karoq Premium | Skoda Online',
                description: 'הסקודה קארוק Premium מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].KODIAQ]: {
            9365: {
                title: 'Skoda Kodiaq Business | Skoda Online',
                description: 'הסקודה קודיאק Business מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            937: {
                title: 'Skoda Kodiaq Ambition | Skoda Online',
                description: 'הסקודה קודיאק Ambition מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            9357: {
                title: 'Skoda Kodiaq Style | Skoda Online',
                description: 'הסקודה קודיאק Style מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            9356: {
                title: 'Skoda Kodiaq Sportline | Skoda Online',
                description: 'הסקודה קודיאק Sportline מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה ',
            },
            9340: {
                title: 'koda Kodiaq L&K | Skoda Online',
                description: 'הסקודה קודיאק L&K מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.SKODA].ENYAQ]: {
            911: {
                title: 'Skoda Enyaq Loft | Skoda Online',
                description: 'הסקודה אניאק Loft מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            912: {
                title: 'Skoda Enyaq Lodge | Skoda Online',
                description: 'הסקודה אניאק Lodge מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            913: {
                title: 'Skoda Enyaq Lounge | Skoda Online',
                description: 'הסקודה אניאק Lounge מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            914: {
                title: 'Skoda Enyaq Suite | Skoda Online',
                description: 'הסקודה אניאק Suite מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            915: {
                title: 'Skoda Enyaq ecoSuite | Skoda Online',
                description: 'הסקודה אניאק ecoSuite מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
            916: {
                title: 'Skoda Enyaq Sportline | Skoda Online',
                description: 'סקודה אניאק Sportline מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של סקודה',
            },
        },
    },
    [MENUFACTURS.CUPRA]: {
        [MODEL_GROUPS_CODE[MENUFACTURS.CUPRA].FORMENTOR]: {
            2796: {
                title: 'CUPRA Formentor | CUPRA Online',
                description: 'הקופרה פורמנטור מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של קופרה',
            },
            2798: {
                title: 'CUPRA Formentor VZ | CUPRA Online',
                description: 'קופרה פורמנטור VZ מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של קופרה',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.CUPRA].ATECA]: {
            3014: {
                title: 'CUPRA Ateca | CUPRA Online',
                description: 'הקופרה אטקה  מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של קופרה',
            },
        },
        [MODEL_GROUPS_CODE[MENUFACTURS.CUPRA].LEON]: {
            2897: {
                title: 'CUPRA Leon | CUPRA Online',
                description: 'הקופרה לאון מחכה לכם כאן באתר במרחק קליקים בודדים! עם מפרט עשיר, מגוון מערכות בטיחות מתקדמות ומחיר מיוחד לאתר האונליין של קופרה',
            },
        },

    },
};

export const GTM_ACTION_FIELDS_STEPS = {
    IDENTIFICATION: 1,
    REGISTRATION: 2,
    PICK_UP_AND_FINANCING: 3,
    PAYMENT_AND_DOWN_PAYMENT: 4,
};

export const GTM_EVENT_TYPES = {
    ADD_TO_CART: 'add_to_cart',
    BEGIN_CHECKOUT: 'begin_checkout',
    CHECKOUT: 'checkout',
    TRANSACTION_COMPLETE: 'transactionComplete',
    PURCHASE: 'purchase',
};

export const TITLE_POSITION = {
    BOTTOM: 'bottom',
    CENTER: 'center',
    TOP: 'top',
};

export const PHONE_PREFIXES = [
    { text: '050', value: '050' },
    { text: '051', value: '051' },
    { text: '052', value: '052' },
    { text: '053', value: '053' },
    { text: '054', value: '054' },
    { text: '055', value: '055' },
    { text: '056', value: '056' },
    { text: '057', value: '057' },
    { text: '058', value: '058' },
    { text: '059', value: '059' },
];

export const OtpHeaders = {
    'OTP-Domain': window.location.host,
};

export const OTP_CREDENTIAL = 'OTPCredential';

export const CONFIGURATOR_QUERY_PARAMS = Object.freeze({
    MODEL: 'modelCode',
    TRIM_LEVEL: 'trimLevelCode',
    MODEL_GROUP: 'modelGroupCode',
    ORIGINAL_ADDITIONS: 'originalAdditions',
    EQUIPMENT_PACKAGE: 'equipmentPackage',
    EXTERNAL_COLOR: 'color',
    INTERNAL_COLOR: 'internalColor',
    HOOPS: 'hoops',
    ADDONS: 'addOns',
    SUMMARY: 'summary',
});

export const DASH_SIGN = '-';
