import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../Divider/Divider';
import Styled from './EquipmentPackageSection.style';

const EquipmentPackageSection = ({
    description,
    details,
}) => {
    const isDetailsExist = details.length > 0;

    return (
        <Styled.Container>
            <Styled.Title>{ description }</Styled.Title>
            { isDetailsExist && (
                <Styled.DetailsList>
                    { details.map((detail) => <Styled.DetailItem>{ detail }</Styled.DetailItem>)}
                </Styled.DetailsList>
            ) }
            <Divider />
        </Styled.Container>
    );
};

EquipmentPackageSection.propTypes = {
    description: PropTypes.string.isRequired,
    details: PropTypes.array.isRequired,
};

export default EquipmentPackageSection;
